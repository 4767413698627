






import Vue from 'vue'

export default Vue.extend({
  name: 'ForgotPassword',
  data () {
    return {
      email: null
    }
  }
})
